import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Button from "../buttons/button"

import Supplement from "./supplement"

import topBlob from "../../images/mainpage/testimonials/blob-bottom-galini.png"

const OurSupplements = () => {
  const {
    allDatoCmsSupplement: { edges: supplementData },
  } = useStaticQuery(graphql`
    query {
      allDatoCmsSupplement {
        edges {
          node {
            id
            slug
            title
            benefits
            description
            boxImage {
              fluid {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <section className="u-standard-section-margins oursupplements">
      <img
        alt="decorative blob in background 2"
        src={topBlob}
        className="oursupplements__topBlob"
      />
      <h1 className="u-margin-bottom-small oursupplements__title">
        Our Supplements
      </h1>
      <ul className="u-display-none-mobile oursupplements__featurelist">
        <li>safe</li>
        <li>non-addictive</li>
        <li>effective</li>
        <li>powerful</li>
        <li>research-backed</li>
        <li className="u-display-none-desktop">natural</li>
      </ul>
      <div className="u-display-none-tablet-and-above u-margin-bottom-big oursupplements__featurelist__phone">
        <span role="img" aria-label="check icon">
          ✔️
        </span>{" "}
        safe{" "}
        <span role="img" aria-label="check icon">
          ✔️
        </span>{" "}
        non-addictive{" "}
        <span role="img" aria-label="check icon">
          ✔️
        </span>{" "}
        effective <br />{" "}
        <span role="img" aria-label="check icon">
          ✔️
        </span>{" "}
        powerful{" "}
        <span role="img" aria-label="check icon">
          ✔️
        </span>{" "}
        research-backed
      </div>

      <div className="u-margin-bottom-medium supplement__grid">
        {supplementData.map(({ node: supplement }) => {
          return (
            <Supplement
              key={supplement.id}
              title={supplement.title}
              slug={supplement.slug}
              benefits={supplement.benefits}
              description={supplement.description}
              fluidImg={supplement.boxImage.fluid}
            />
          )
        })}
      </div>
      <Button type="primary" text="Shop All Products" link="shop" />
    </section>
  )
}

export default OurSupplements
