import React from "react"

import Pricing from "./pricing"

import topBlob from "../../images/mainpage/blobs/topBlob.png"
import bottomBlob from "../../images/mainpage/blobs/bottomBlob.png"

//MAKE PRICE TIER COMPONENT***************

const MarketTiers = () => {
  return (
    <section className="u-standard-section-margins markettiers">
      <img
        alt="decorative blob in background 1"
        src={topBlob}
        className="markettiers__topBlob"
      />
      <img
        alt="decorative blob in background 2"
        src={bottomBlob}
        className="markettiers__bottomBlob"
      />

      <h1>Try One of Our Samplers</h1>
      <h2>Money Back Guarantee · 24/7 Chat Support</h2>
      <div className="markettiers__highlight">
        <p>
          Our recommended, most popular offering. A one-month experience,
          guaranteed relief.
        </p>
      </div>

      <div className="pricing__grid">
        <Pricing
          title="Discovery"
          price="$19.99"
          marketPrice="$50"
          savings="$25"
          amount="14"
        />
        <Pricing
          title="Relief"
          price="$29.99"
          marketPrice="$100"
          savings="$55"
          amount="28"
        />
        <Pricing
          title="Ultimate"
          price="$39.99"
          marketPrice="$170"
          savings="$105"
          amount="42"
        />
      </div>
    </section>
  )
}

export default MarketTiers
