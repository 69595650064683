import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Main from "../components/MainPage/main"


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Main />
    
    
    {/* <ul>
      <li><Link to="/quiz/">1. Try the quiz</Link></li>
      <li><Link to="/shop">2. Check out the product page</Link></li>
      <li><button className="snipcart-summary snipcart-checkout">MY CART</button></li>
      <li><button className="snipcart-user-profile">PROFILE</button></li>
    </ul>

    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> <br />
    TESTESTESTEST
    <Link to="/page-3/">Go to page 3</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}

  </Layout>
)

export default IndexPage
