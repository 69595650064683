import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Button from "../buttons/button"

const FinalCTA = () => {
  const image = useStaticQuery(graphql`
    query {
      file(
        relativeDirectory: {}
        relativePath: { eq: "mainpage/cta-portrait.png" }
      ) {
        id
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="u-standard-section-margins finalcta">
      <div className="finalcta__left">
        <h1 className="finalcta__title u-margin-bottom-medium">Don't settle with <br /> being stressed</h1>

        <ul className="u-margin-bottom-medium finalcta__list">
          <li className="finalcta__list__item">
            5 anti-anxiety supplements for the price of 1
          </li>
          <li className="finalcta__list__item">
            Proven, safe, non-addictive, research-backed
          </li>
          <li className="finalcta__list__item">
            Anxiety tracking calendar and mood stickers
          </li>
          <li className="finalcta__list__item">Money back guarantee</li>
          <li className="finalcta__list__item">Chat and phone Support</li>
        </ul>

        <p className="finalcta__price">
          Starting at <span>$19.99</span>
        </p>
        <Button type="primary" text="Try the quiz" link="quiz" />
      </div>

      <div className="finalcta__right">
        <Img
          fluid={image.file.childImageSharp.fluid}
          alt="Smiling woman looking at screen"
          className="finalcta__image"
        />
      </div>
    </section>
  )
}

export default FinalCTA
