import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import topBlob from "../../images/mainpage/testimonials/blob-top-galini.png"
import bottomBlob from "../../images/mainpage/testimonials/blob-bottom-galini.png"

const Testimonials = () => {
  //destructuring -->
  const { file: {childImageSharp: {fluid: testimonialImage }} } = useStaticQuery(graphql`
    query imageQuery {
      file(
        relativePath: {
          eq: "mainpage/testimonials/testimonial-galini-cynthia.png"
        }
      ) {
        id
        base
        childImageSharp {
          id
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `)

  return (
    <section className="testimonials">
      <img
        alt="decorative blob in background 1"
        src={topBlob}
        className="testimonials__topBlob"
      />
      <img
        alt="decorative blob in background 2"
        src={bottomBlob}
        className="testimonials__bottomBlob"
      />
      <div className="u-standard-section-margins testimonials__grid">
        <Img
          className="testimonials__customerImg"
          fluid={testimonialImage}
          alt="Older woman reclining happily"
        />
        <div className="testimonials__details">
          <h1>Martha's Story</h1>
          <p>Teacher Galini Customer for 4 months</p>
          <p>
            "For years I suffered with anxiety or tried to self-medicate, until
            trying Galini I didn’t realize how many supplements there are out
            there that actually work."
          </p>
          <h2>What works best for Martha</h2>
          <p>
            <span>Lavender</span> for easing nerves, enhancing mood and aiding
            sleep
            <br />
            <span>Phosphatidylserine</span> for improving brain function
          </p>
          <h2>What she's experienced</h2>
          <p>
            Improvements in mood and energy after 3 weeks
            <br />
            Less fatigue and anxious through out the day
            <br />
            Provides better care for her students and grandchildren
          </p>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
