import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

//better to import all the images here and have a switch case here??
// import lemonBalmImg from "../../images/mainpage/supplements/Lemon-Balm-Our-Ingredients.png"

const Supplement = props => {
  const benefitsArray = props.benefits.split(",")
  return (
    <Link className="supplement__linkbox" to={`/learn/${props.slug}`}>
      <div className="supplement" key={props.id}>
        
        <div className="supplement__image">
          <Img fluid={props.fluidImg} alt={props.title} />
        </div>

        <div className="supplement__details">
          <div>
            <h1 className={`u-margin-bottom-tiny supplement__details__heading${props.title === 'Phosphatidylserine' ? '__phos' : ''}`}>{props.title}</h1>
            <ul className="supplement__benefits">
              {benefitsArray.map(benefit => {
                return (
                  <li className="u-margin-right-small" key={benefit}>
                    {benefit}
                  </li>
                )
              })}
            </ul>
          </div>
          <p className="u-margin-top-small supplement__description">
            {props.description}
          </p>
          <div className="u-margin-top-small">
            <p className="supplement__link" to={`/learn/${props.slug}`}>
              Click to Learn More
            </p>
          </div>
        </div>

      </div>
    </Link>
  )
}

export default Supplement
