import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ValueProp = () => {
  const allImagesData = graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(png)/" }
          relativeDirectory: { eq: "mainpage/valueprop" }
        }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              fluid {
                aspectRatio
                base64
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `

  const {
    allFile: { edges: images },
  } = useStaticQuery(allImagesData)

  const image2 = images[0].node.childImageSharp.fluid
  const image1 = images[1].node.childImageSharp.fluid

  return (
    <section className="u-standard-section-margins valueprop">
      <div className="valueprop__left">
        <h2 className="u-vis-hide u-display-none-tablet">HIDE THIS TEXT</h2>
        <div>
          <Img
            fluid={image1}
            alt="Cartoon of person happy with Galini's supplement options"
            className="u-margin-top-medium"
          />
        </div>
        <div className="u-margin-top-medium">
          <h2>Don't be Overwhelmed</h2>
          <p>
            There are so many available supplements for anxiety it's
            overwhelming and you don't know where to start. What is right for
            you?
          </p>
        </div>
      </div>

      <div className="valueprop__right">
        <h2>With Galini's Reflief Pack</h2>
        <div>
          <Img
            fluid={image2}
            alt="Cartoon of person confused by expensive supplement options"
            className="u-margin-top-medium" 
          />
        </div>
        <div className="u-margin-top-medium">
          <h2>Formulated For You</h2>
          <p>
            We personalize a plan for to give you the most effective supplements
            to treat the type of stress and anxiety you experience.
          </p>
        </div>
      </div>
    </section>
  )
}

export default ValueProp
