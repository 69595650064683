import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Button from "../buttons/button"

const Splash = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "anxiety-relief-package-galini-ring.png"}) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="u-standard-margins u-padding-bottom-small splash-color">
      <div className="splash u-padding-top-small u-padding-bottom-small">
        <div className="splash__img__container">
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="The Galini Anxiety Relief Package with 5 Stress Relief Supplements"
            className="splash__image"
          />
        </div>
        <div className="splash__details">
          <h1 className="u-margin-bottom-medium">Personalized <br /> Anxiety Supplements <br /> Just for you</h1>
          <p className="u-margin-bottom-medium">
            Anxiety treatment is not one size fits all. You're unique so don't
            settle for generic supplements. Take our free quiz now to learn what
            supplements are best for you.
          </p>
          <Button 
            type="primary"
            text="Try the quiz"
            link="quiz"
          />
        </div>
      </div>
    </div>
  )
}

export default Splash
