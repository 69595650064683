import React from "react"
import Splash from "./splash"
import Testimonials from "./testimonials"
import ValueProp from "./valueprop"
import OurSupplements from "./oursupplements"
import MarketTiers from "./markettiers"
import FinalCTA from "./finalcta"

const Main = () => {
    return(
        <div>
            <Splash />
            <Testimonials />
            <ValueProp />
            <OurSupplements />
            <MarketTiers />
            <FinalCTA />
        </div>
    );
}

export default Main;