import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Button from "../buttons/button"

//styling in markettiers scss file

const Pricing = props => {
  const {
    allFile: { edges: images },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "mainpage/tiers" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  let image
  let pathName

  switch (props.title) {
    case "Discovery":
      image = images[0].node.childImageSharp.fluid;
      pathName = "discovery-pack";
      break
    case "Relief":
      image = images[1].node.childImageSharp.fluid;
      pathName = "relief-pack";
      break
    case "Ultimate":
      image = images[2].node.childImageSharp.fluid;
      pathName = "ultimate-pack";
      break
    default:
      break
  }

  return (
    <div
      className={
        props.title === "Ultimate"
          ? "pricing pricing__ultimate"
          : props.title === "Relief"
          ? "pricing pricing__relief"
          : "pricing"
      }
    >
      <h2 className="pricing__title">{props.title} Package</h2>
      <h1 className="pricing__price">{props.price}</h1>
      <p className="pricing__marketcost">
        Market Cost {props.marketPrice} <span>Save {props.savings}</span>
      </p>
      <hr />
      <p className="pricing__packamount">
        <span>{props.amount}</span> Anxiety Relief Packs
      </p>
      <ul className="pricing__featurelist">
        <li className="pricing__featurelist__item pricing__featurelist__item--active">
          Easy Soothing Sleep
        </li>
        <li className="pricing__featurelist__item pricing__featurelist__item--active">
          Calm Productive Focus
        </li>
        <li
          className={
            props.title === "Discovery"
              ? "pricing__featurelist__item"
              : "pricing__featurelist__item pricing__featurelist__item--active"
          }
        >
          Clear Brain Fog
        </li>
        <li
          className={
            props.title === "Discovery"
              ? "pricing__featurelist__item"
              : "pricing__featurelist__item pricing__featurelist__item--active"
          }
        >
          Relaxed Social Flow
        </li>
        <li
          className={
            props.title === "Ultimate"
              ? "pricing__featurelist__item pricing__featurelist__item--active"
              : "pricing__featurelist__item"
          }
        >
          Clear Calm Boost
        </li>
        <li
          className={
            props.title === "Ultimate"
              ? "pricing__featurelist__item pricing__featurelist__item--active"
              : "pricing__featurelist__item"
          }
        >
          Ultimate Panic Relief
        </li>
        <li
          className={
            props.title === "Ultimate"
              ? "pricing__featurelist__item pricing__featurelist__item--active"
              : "pricing__featurelist__item"
          }
        >
          Anxiety Tracking Calendar
          <br />
          with Mood stickers
        </li>
      </ul>
      <hr />

      <Img fluid={image} />

      <br />
      <div className="pricing__button">
        <Button 
          type="primary" text={`Get ${props.title}`} link={`shop/box/${pathName}`} />
      </div>
    </div>
  )
}

export default Pricing
